:root {
    --panel-bg-color: #243c54;
    --panel-txt-color: #f4dcbb;
    --normal-txt-color: #243c54;
    --hover-txt-color: #A90FD4;
    --shadow-color: #0b1629;
    --work-table-active-color: 36, 60, 84;
    --work-table-back-color: 36, 60, 84;
    --header-color: 250, 250, 250;
    --resume-color: #E52DF5;
    --bold-description-color: rgb(179, 143, 45);
    --project-link-color: rgb(106, 183, 255);
    --project-link-hover-color: rgb(90, 175, 255);
  }