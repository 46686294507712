* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;

  font-family: 'Lato', sans-serif;
}

.app {
  text-align: center;
  overflow-x: hidden;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

#Heading {
  position: fixed;
  backdrop-filter: blur(10px);
  height: 7vh;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 1px 10px var(--shadow-color);
  background-color: rgba(var(--header-color), 0.5);
  overflow-x: hidden;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  font-size: 1.7vh;

  transition: all 300ms ease-in-out;
}

.header-animation {
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

#Heading a {
  margin-left: 40px;
  margin-right: 40px;
  text-decoration: none;
  color: var(--normal-txt-color);
}

#Heading a:hover {
  color: var(--hover-txt-color) !important;
  text-decoration: underline;
}

#Resume-head {
  width: 5vw;   /* to push away from scroll bar */
  /*text-decoration: underline !important;
  color: var(--resume-color) !important; */
}

main {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100vh 53.3vh 1100px 560px 60px;
  grid-template-areas:
  "top1"
  "about"
  "experience"
  "contact"
  "credits";
}

#Top {
  grid-area: top1;
  color: var(--normal-txt-color);
  z-index: 998;
}

#About {
  grid-area: about;
  text-align: center;
  background-color: var(--panel-bg-color);
  color: var(--panel-txt-color);
  z-index: 997;
}

#Experience {
  grid-area: experience;
  color: var(--normal-txt-color);
  overflow-x: hidden;
}

#Contact {
  grid-area: contact;
  background-color: var(--panel-bg-color);
  color: var(--panel-txt-color);
  overflow-x: hidden;
}

#Credits {
  grid-area: credits;
  background-color: var(--panel-bg-color);
  color: var(--panel-txt-color);
  overflow-x: hidden;
}

.scroll-down #Heading {
  transform: translate3d(0, -100%, 0);
}

.scroll-up #Heading {
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-height: 900px) {
  main {
    grid-template-rows: 100vh 501.86px 1100px 560px 60px;
  }

  #Heading {
    height: 11vh;
    font-size: 2.1vh;
  }
}

@media only screen and (min-width: 1500px) {
  main {
    grid-template-rows: 100vh 53.3vh 1100px 560px 60px;
  }
}

@media only screen and (max-height: 1051px) {
  main {
    grid-template-rows: 100vh 55.3vh 1000px 560px 60px;
  }
}

@media only screen and (max-height: 780px) {
  main {
    grid-template-rows: 100vh 64vh 1000px 560px 60px;
  }
}

@media only screen and (max-height: 650px) {
  main {
    grid-template-rows: 100vh 84vh 1000px 560px 60px;
  }
}