@import './colors.css';

#contact-container {
    font-family: 'Lato', 'sans-serif';

    display: grid;
    grid-template-columns: 1fr 500px 500px 1fr;
    grid-template-rows: 1fr 100px 150px 85px 115px 3fr;
    grid-template-areas:
        ". . . ."
        ". Title Title ."
        ". pls pls ."
        ". Phone Email ."
        ". Github Linkedin ."
        ". . . .";

    height: 100%;
    width: 100%;
    text-align: center;

}

#title {
    grid-area: Title;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 60px;
    font-weight: bold;
}

#job {
    grid-area: pls;
    font-size: 25px;
    padding-left: 100px;
    padding-right: 100px;
    line-height: 39px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

#phone {
    grid-area: Phone;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 25px;
}

#email {
    grid-area: Email;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    
    font-size: 25px;
}

#github {
    grid-area: Github;
    display: flex;
    align-items: center;
    justify-content: center;
}

#linkedin {
    grid-area: Linkedin;
    display: flex;
    align-items: center;
    justify-content: center;
}

.favicons {
    font-size: 70px;
    color: var(--panel-txt-color);
    text-decoration: none;
}

a:hover, a:focus, a:active, a:visited, a:link{
    text-decoration: none;
}

@media only screen and (max-height: 760px) {
    #title {
        font-size: 3.3vw;
    }

    #job {
        font-size: 1.6vw;
    }

    #phone {
        font-size: 1.6vw;
    }

    #email {
        font-size: 1.6vw;
    }

    .favicons {
        font-size: 3.5vw;
    }
}