#top-container {
    font-family: 'Lato', 'sans-serif';

    display: grid;
    grid-template-columns: 2.5fr 40vw 22vw 2fr;
    grid-template-rows: 1fr 11.6vh 8vh 13.9vh 1fr;
    grid-template-areas:
        ". . . ."
        ". Hi Pic ."
        ". Name Pic ."
        ". Welcome Pic ."
        ". . . .";

    height: 100%;
    width: 100%;
    text-align: left;

    background-image: url("./Assets/WebsiteBackground.png");
    background-repeat: no-repeat;
    background-size: cover;

    box-shadow: 0px 15px 22px var(--shadow-color);

    animation: fadeInAnimation ease 4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#Hi {
    grid-area: Hi;

    display: flex;
    align-items: flex-end;

    font-size: 1.8vh;
}

#Name {
    grid-area: Name;

    display: flex;
    align-items: center;

    font-size: 4.7vh;
    font-weight: bold;
}

#Welcome {
    grid-area: Welcome;

    display: flex;
    align-items: flex-start;
    font-size: 2vh;
}

#Picture-container {
    grid-area: Pic;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#Pic {
    height: auto;
    max-width: 100%;
}

@media only screen and (max-height: 900px) {
    #Hi {
        font-size: 2.5vh;
    }
    
    #Name {
        font-size: 5.3vh;
    }
    
    #Welcome {
        font-size: 2.8vh;
    }
}
