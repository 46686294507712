#about-container {
    font-family: 'Lato', 'sans-serif';

    display: grid;
    grid-template-columns: 1fr 38vw 13vw 19vw 1fr;
    grid-template-rows: 0.7fr 5.2vh 26vh 1fr;
    grid-template-areas:
    " . . . . ."
    ". title . picture ."
    ". text . picture ."
    ". . . . .";

    height: 100%;
    width: 100%;
    text-align: left;

    box-shadow: 0px 15px 22px var(--shadow-color);
}

#about-heading {
    grid-area: title;
    display: flex;

    align-items: flex-end;
    font-size: 220%;
    font-weight: bold;
    padding-bottom: 20px;
}

#about-body {
    grid-area: text;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    font-size: 110%;
    line-height: 130%;
}

#pic-container {
    grid-area: picture;
    height: 100%;

    display: flex;
    align-items: start;
    justify-content: center;
}

#pic {
    height: auto;
    width: auto;
    max-height: 120%;
    box-shadow: 13px 13px 10px var(--shadow-color);
}

@media only screen and (min-width: 1500px) {
    #about-heading {
        font-size: 400%;
        font-weight: bold;
    }
    
    #about-heading {
        font-size: 3.2vmin;
    }

    #about-body {
        font-size: 2vmin;
        line-height: 3vmin;
    }
}

@media only screen and (max-width: 1500px) {
    #about-container {
        grid-template-columns: 1fr 38vw 0.5fr 35vw 1fr;
    }

    #pic {
        max-width: 100%;
        max-height: 120%;
    }
}

@media only screen and (max-width: 1030px) {
    #about-body {
        font-size: 2vmin;
        line-height: 3vmin;
    }
}

@media only screen and (max-height: 780px) {
    #about-container {
        grid-template-rows: 0.5fr 5.2vh 35vh 1fr;
    }

    #pic-container {
        grid-area: picture;
        height: 100%;
    
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    
  }
