.description-container {
    font-family: 'Lato', 'sans-serif';

    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 7% 1fr 7%;
    grid-template-rows: 0.5fr 80px 40px 450px 1fr; 
    grid-template-areas:
    ". . ."
    ". heading ."
    ". date ."
    ". details. "
    ". . .";
}

.job-heading {
    grid-area: heading;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 180%;
    font-weight: bold;
}

.date {
    grid-area: date;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 130%;
}

.details {
    grid-area: details;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;

    padding-top: 20px;

    font-size: 140%;
    line-height: 170%;
}

@media only screen and (max-width: 1500px) {
    .details {
        font-size: 130%;
        line-height: 170%;
    }
}