#experience-container {
    font-family: 'Lato', 'sans-serif';
    height: 100%;
    width: 100vw;

    background-image: url('./Assets/webExpBackground.png');
    background-repeat: no-repeat;
    background-size: cover;

    display: grid;
    grid-template-columns: 1fr 80vw 1fr;
    grid-template-rows: 5% 12% 63% 1fr 15%;
    grid-template-areas: 
    ". . ."
    ". heading ."
    ". table ."
    ". . ."
    "curve curve curve";
}

#heading {
    grid-area: heading;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 300%;
    font-weight: bold;
    padding-bottom: 50px;
}

#table {
    grid-area: table;
    display: grid;
    grid-template-columns: 1fr 20% 80% 1fr;
    grid-template-areas:
    ". jobs description .";

    color: var(--panel-txt-color);
    box-shadow: 8px 10px 20px var(--shadow-color);
}

/* To add new jobs, just add another li to "job-listing" */

#job-listing-container {
    grid-area: jobs;
    display: flex;
    flex-direction: column;
}

#job-listing {
    font-size: 140%;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.job-list {
    background-color: rgba(var(--work-table-active-color), 0.86);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    height: 90px;
    list-style-type: none;
}

.job-list:hover {
    background-color: rgba(var(--work-table-active-color), 0.90);
    cursor: pointer;
}

#filler {
    height: 100%;
    width: 100%;
    background-color: rgba(var(--work-table-back-color), 0.6);
}

#job-descriptions {
    grid-area: description;
    background-color: rgba(var(--work-table-active-color), 0.95);
    height: 100%;
}

#curve {
    grid-area: curve;
    box-shadow: 0px -100px 22px -3 var(--shadow-color);
}

li b {
    color: var(--bold-description-color);
}

p a {
    color: var(--project-link-color) !important;
    text-decoration: underline !important;
}

p a:hover {
    color: var(--project-link-hovor-color) !important;
    text-decoration: underline;
}

li a {
    color: var(--project-link-color) !important;
    text-decoration: underline !important;
}

li a:hover {
    color: var(--project-link-hovor-color) !important;
    text-decoration: underline;
}

@media only screen and (min-width: 2000px) {
    #experience-container {
        display: grid;
        grid-template-columns: 1fr 60vw 1fr;
        grid-template-rows: 5% 15% 57% 1fr 18%;
        grid-template-areas: 
        ". . ."
        ". heading ."
        ". table ."
        ". . ."
        "curve curve curve";
    }

}

@media only screen and (max-width: 1500px) {
    #experience-container {
        display: grid;
        grid-template-columns: 1fr 1100px 1fr;
        grid-template-rows: 6% 8% 64% 1fr 18%;
        grid-template-areas: 
        ". . ."
        ". heading ."
        ". table ."
        ". . ."
        "curve curve curve";
    }

    #heading {
        align-items: start;
    }
}